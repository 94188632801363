import React from "react";
import techaniLogo from "../assets/Techani negro letras.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { supabase } from "../config/supabase";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../context/UserContext";

export default function Login() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const onSubmit = (
    { email, password },
    { setSubmitting, resetForm, setErrors }
  ) => {
    supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (user.role === "administrador") {
      navigate("/main");
    } else {
      return setErrors({ password: "Credenciales no válidas" });
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Correo Electronico Inválido")
      .required("El correo es requerido"),
    password: Yup.string().required("La contraseña es requerida"),
  });

  return (
    <div className="bg-[#277BC0] w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[45rem] h-96 flex flex-col items-center justify-center bg-white mt-5 rounded-lg shadow-lg shadow-slate-700">
        <img src={techaniLogo} alt="techani_logo" width={80} height={80} />
        <h1 className="text-2xl font-semibold mb-2 mt-2">Bienvenido</h1>
        <p className="text-gray-400 mb-5">
          Ingrese sus credenciales para iniciar
        </p>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form
              className="flex flex-col items-center justify-center w-3/4"
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Usuario"
                className="focus:ring-0 border-b-2 border-l-0 border-t-0 border-r-0 focus:border-transparent focus:outline-none focus:border-b-black focus:border-t-0 transition duration-300 mb-1  border-gray-300 py-2  w-3/4"
                autoComplete="off"
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Contraseña"
                className="focus:ring-0  border-b-2 border-l-0 border-t-0 border-r-0 focus:border-transparent focus:outline-none focus:border-b-black focus:border-t-0 transition duration-300 mb-1  border-gray-300 py-2 w-3/4"
                autoComplete="off"
              />
              {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
              <button
                className="w-3/4 bg-[#277BC0] text-white py-1 mt-5"
                type="submit"
              >
                Iniciar sesión
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
